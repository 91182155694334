<template>
  <!-- 新建方案 -->
  <el-dialog
    :visible.sync="dialogTableVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="handleClose"
    width="40%"
  >
    <div slot="title" class="dialog-header">
      <span v-show="type == 1">新建监测方案</span>
      <span v-show="type == 2">编辑监测方案</span>
    </div>
    <div class="dialog-body">
      <!-- 方案名称 or 开始时间 -->
      <el-row :gutter="60" style="display: flex; flex-wrap: wrap">
        <!-- <el-col :span="11">
          <div class="dialog-body-desc">上级方案</div>
          <el-select
            style="width: 100%"
            class="m-t-16"
            :disabled="disableding && !PlanData.planMonitor.parentId"
            v-model="PlanData.planMonitor.parentId"
            placeholder="请选择方案"
          >
            <el-option
              v-for="item in monitorData"
              :key="item.value"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col> -->
        <!-- <el-col :span="11">
          <div class="dialog-body-desc">
            <span class="redtext">*</span>开始时间
          </div>
          <el-date-picker
            style="width: 100%"
            class="m-t-16"
            v-model="PlanData.planMonitor.startTime"
            type="datetime"
            size="medium"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-col> -->
        <!-- 监测方式 -->
        <el-col :span="11">
          <div class="dialog-body-desc">
            <span class="redtext">*</span>方案名称
          </div>
          <el-input
            style="width: 100%"
            class="m-t-16"
            v-model.trim="PlanData.planMonitor.name"
            placeholder="请输入内容"
            clearable
          ></el-input>
        </el-col>
        <el-col :span="11">
          <div class="dialog-body-desc">
            <span class="redtext">*</span>预警方式
          </div>
          <el-select
            style="width: 100%"
            v-model="warnTypeIng.warnType"
            placeholder="请选择"
            class="m-t-16"
          >
            <el-option
              v-for="item in warningModeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <div class="flex warningFlex m-t-16" v-show="warnTypeIng.warnType == 1">
        <el-input
          class="warningInput"
          v-model.trim="warnSHuru.warnKey"
          placeholder="请输入预警关键字"
          clearable
        ></el-input>
        <div class="btn warningBtn m-l-16" @click="warnKeywordFn(1)">添加</div>
      </div>
      <div class="flex warningFlex m-t-16" v-show="warnTypeIng.warnType == 2">
        <el-input
          class="warningInput"
          v-model.trim="warnSHuru.warnAccountKey"
          placeholder="请输入预警账号"
          clearable
        ></el-input>
        <div class="btn warningBtn m-l-16" @click="warnKeywordFn(2)">添加</div>
      </div>
      <div class="flex warningFlex m-t-16" v-show="warnTypeIng.warnType == 3">
        <el-input
          class="warningInput"
          v-model.trim="warnSHuru.warnWebsiteKey"
          placeholder="请输入预警网站"
          clearable
        ></el-input>
        <div class="btn warningBtn m-l-16" @click="warnKeywordFn(3)">添加</div>
      </div>
      <!-- 关键词预警列表 -->
      <div class="guanjianci m-t-16" v-if="warnSHuru.warnKeyword.length > 0">
        <div class="guanjianci-L">关键词：</div>
        <div class="guanjianci-R flex">
          <el-tag
            class="m-r-16"
            style="
              color: #333333;
              background-color: #f4f4f5;
              border-color: #e9e9eb;
            "
            v-for="(tag, inx) in warnSHuru.warnKeyword"
            :key="inx"
            closable
            @close="taghandleClose(inx, 1)"
            type="danger"
          >
            {{ tag }}
          </el-tag>
        </div>
      </div>
      <!-- 定向账号预警列表 -->
      <div class="guanjianci m-t-16" v-if="warnSHuru.warnAccount.length > 0">
        <div class="guanjianci-L">账号预警:</div>
        <div class="guanjianci-R flex">
          <el-tag
            class="m-r-16"
            style="
              color: #333333;
              background-color: #f4f4f5;
              border-color: #e9e9eb;
            "
            v-for="(tag, inx) in warnSHuru.warnAccount"
            :key="inx"
            closable
            @close="taghandleClose(inx, 2)"
            type="danger"
          >
            {{ tag }}
          </el-tag>
        </div>
      </div>
      <!-- 定向网站预警列表 -->
      <div class="guanjianci m-t-16" v-if="warnSHuru.warnWebsite.length > 0">
        <div class="guanjianci-L">网站预警:</div>
        <div class="guanjianci-R flex">
          <el-tag
            class="m-r-16"
            style="
              color: #333333;
              background-color: #f4f4f5;
              border-color: #e9e9eb;
            "
            v-for="(tag, inx) in warnSHuru.warnWebsite"
            :key="inx"
            closable
            @close="taghandleClose(inx, 3)"
            type="danger"
          >
            {{ tag }}
          </el-tag>
        </div>
      </div>

      <el-row :gutter="60">
        <el-col :span="13">
          <div class="dialog-body-desc m-t-16">
            <span class="redtext">*</span>监测方式
          </div>
          <el-radio-group v-model="redio" class="m-t-big" @change="planTypeFn">
            <el-radio :label="1" :disabled="disableding">设置关键字</el-radio>
            <!-- <el-radio :label="2" :disabled="disableding">设置定向监测</el-radio> -->
          </el-radio-group>
        </el-col>
      </el-row>
      <!-- 设置关键字 -->

      <template v-if="redio == 1">
        <!-- *数据范围 -->
        <div class="dialog-body-desc m-t-16">
          <span class="redtext">*</span>数据范围
        </div>
        <div class="flex m-t-16">
          <el-checkbox
            :indeterminate="PlatformObj.isIndeterminate"
            v-model="PlatformObj.checkAll"
            @change="CheckAllFn(1, $event)"
            >全选</el-checkbox
          >
          <el-checkbox-group
            class="m-l-16"
            v-model="PlatformObj.checkedform"
            @change="selectCheckAllFn(1, $event)"
          >
            <el-checkbox
              v-for="(item, index) in PlatformObj.checkedData"
              :label="item"
              :key="index"
              >{{ item.label }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
        <!-- 主体类型 -->
        <div class="dialog-body-desc m-t-16">
          <span class="redtext">*</span>主体类型
        </div>
        <div class="flex m-t-16">
          <el-checkbox
            :indeterminate="subjectObj.isIndeterminate"
            v-model="subjectObj.checkAll"
            @change="CheckAllFn(2, $event)"
            >全选</el-checkbox
          >
          <el-checkbox-group
            class="m-l-16"
            v-model="subjectObj.checkedform"
            @change="selectCheckAllFn(2, $event)"
          >
            <el-checkbox
              v-for="(item, index) in subjectObj.checkedData"
              :label="item"
              :key="index"
              >{{ item.label }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </template>
      <div style="padding: 16px 110px 0 110px">
        <el-divider>{{
          redio == 1 ? "关键词设置" : "定向监测设置"
        }}</el-divider>
      </div>
      <!-- 设置关键词组 -->
      <template v-if="redio == 1">
        <div class="dialog-body-desc m-t-16">关键词组</div>
        <el-row :gutter="20" class="m-t-16">
          <el-col :span="4">
            <el-input
              v-model="AddKeyIng.keywordName"
              placeholder="词组名称1"
            ></el-input
          ></el-col>
          <el-col :span="6">
            <el-input
              v-model="AddKeyIng.subjectKeywordName"
              placeholder="设置主关键词"
            ></el-input
          ></el-col>
          <el-col :span="6">
            <el-input
              v-model="AddKeyIng.viceKeywordName"
              placeholder="设置副关键词"
            ></el-input
          ></el-col>
          <el-col :span="6">
            <el-input
              v-model="AddKeyIng.secondaryKeywordName"
              placeholder="设置次关键词"
            ></el-input
          ></el-col>
          <el-col :span="2">
            <div class="yellow input-flex-item cursor m-t-8" @click="addKeyFn">
              添加
            </div></el-col
          >
        </el-row>
        <!-- 关键词组列表显示 -->
        <div
          class="flex m-t-16"
          style="background: #f5f6fa; padding: 16px 24px"
          v-for="(item, index) in PlanData.keywordsList"
          :key="index"
        >
          <el-row :gutter="20">
            <el-col :span="8" class="flex-center">
              <el-input
                v-model="item.keywordName"
                placeholder="词组名称不能为空"
              ></el-input>
            </el-col>
            <el-col :span="12">
              <el-input
                v-model="item.subjectKeywordName"
                placeholder="主关键词不能为空"
              ></el-input>
              <el-input class="m-t-8" v-model="item.viceKeywordName"></el-input>
              <el-input
                class="m-t-8"
                v-model="item.secondaryKeywordName"
              ></el-input>
            </el-col>
            <el-col :span="2" class="flex-center">
              <div
                class="red cursor"
                @click="PlanData.keywordsList.splice(index, 1)"
              >
                删除
              </div>
            </el-col>
          </el-row>

          <!-- <div class="flex-l">
          
          </div>
          <div class="flex-c m-l-16">
          
          </div> -->
        </div>
      </template>
      <!-- 设置定向监测 -->
      <template v-if="redio == 2">
        <div class="dialog-body-desc m-t-16">
          <span class="redtext">*</span>定向平台
        </div>
        <el-row :gutter="20" class="m-t-16">
          <el-col :span="8">
            <el-select
              v-model="directionalIng.platformType"
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in PlatformObj.checkedData"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="16">
            <el-input
              v-model.trim="directionalIng.accountName"
              placeholder="请输入名称"
            ></el-input>
          </el-col>
          <el-col :span="20" class="m-t-16">
            <el-input
              v-model.trim="directionalIng.platformUrl"
              placeholder="网址"
            ></el-input>
          </el-col>
          <el-col :span="4" class="m-t-16">
            <div class="btn" @click="addDirectionalFn">添加</div>
          </el-col>
        </el-row>
        <div
          class="zxptList m-t-16"
          style="background: #f5f6fa; padding: 16px 24px"
          v-for="(item, index) in PlanData.directionalList"
          :key="index"
        >
          <el-row :gutter="20">
            <el-col :span="4">
              <el-select v-model="item.platformType" placeholder="请选择">
                <el-option
                  v-for="(item, index) in PlatformObj.checkedData"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="4">
              <el-input
                v-model.trim="item.accountName"
                placeholder="请输入名称"
              ></el-input
            ></el-col>
            <el-col :span="14">
              <el-input
                v-model.trim="item.platformUrl"
                placeholder="网址"
              ></el-input
            ></el-col>
            <el-col :span="2">
              <div
                class="red cursor m-t-8"
                @click="PlanData.directionalList.splice(index, 1)"
              >
                删除
              </div>
            </el-col>
            <!--             
            <el-col :span="4"
              ><div class="red cursor flex-center" @click="PlanData.directionalList.splice(index, 1)"
                >删除</div
              ></el-col
            > -->
          </el-row>
        </div>
      </template>
      <template v-if="redio == 1">
        <!-- *忽略关键词 -->
        <div class="dialog-body-desc m-t-16">忽略关键词</div>
        <el-input
          class="m-t-16"
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="PlanData.planMonitor.ignoreKeyword"
        >
        </el-input>
        <!-- *忽略关键词 -->
        <div class="dialog-body-desc m-t-16">排除关键词</div>
        <el-input
          class="m-t-16"
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="PlanData.planMonitor.excludeKeyword"
        >
        </el-input>
      </template>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose" :disabled="PlanData.loading"
        >取 消
      </el-button>
      <el-button @click="okFn(0)" :disabled="PlanData.loading"
        >保存并运行
      </el-button>
      <el-button @click="okFn(1)" type="primary" :disabled="PlanData.loading"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      monitorData: [],
      disableding: false,
      type: 1,
      // 基础参数 || 公共参数
      // 平台类型 || 数据范围
      PlatformObj: {
        checkAll: false, // 平台类型全选
        checkedform: [], //平台类型已选
        checkedData: JSON.parse(window.localStorage.getItem("platform_type")), //平台类型 可选数据
        isIndeterminate: false, //平台类型不确定状态
      },
      // 主体类型
      subjectObj: {
        checkAll: false, // 主体类型全选
        checkedform: [], //主体类型已选
        checkedData: JSON.parse(window.localStorage.getItem("body_type")), //主体类型
        isIndeterminate: false, //主体类型不确定状态
      },
      //机构预警选择项
      warningModeList: [
        { value: 0, label: "不预警" },
        { value: 1, label: "关键词预警" },
        { value: 2, label: "定向账号预警" },
        { value: 3, label: "定向网站预警" },
      ],
      // 预警信息输入栏
      warnSHuru: {
        warnKey: "", // 预警关键词
        warnAccountKey: "", // 预警账号
        warnWebsiteKey: "", // 预警网站

        warnKeyword: [], //预警关键词列表
        warnAccount: [], //预警账号
        warnWebsite: [], //预警网
      },
      // 预警类型给后端的栏
      warnTypeIng: {
        //预警类型(1关键词预警、2定向账号预警、3定向网站预警,不预警不传参数)
        warnType: 0, //预警类型
        warnKeyword: [], //预警关键词列表
        warnAccount: [], //预警账号
        warnWebsite: [], //预警网站
      },
      // 关键词组输入栏
      AddKeyIng: {
        keywordName: "", // 关键词组名称
        subjectKeywordName: "", //	主关键词
        viceKeywordName: "", //副关键词
        secondaryKeywordName: "", //次关键词
      },
      //   定向信息输入栏
      directionalIng: {
        platformType: "", //平台类型
        accountName: "", // 平台账号名称
        platformUrl: "", //账号网址
      },
      PlanData: {
        // 需要给后端的参数
        planMonitor: {
          parentId: null,
          name: "", // 方案名称
          startTime: "", // 开始时间
          platformTypes: "", // 数据范围
          bodyType: "", // 主体类型
          ignoreKeyword: "", //忽略关键词
          excludeKeyword: "", // 排除关键字
        },
        warnList: [], // 预警类型
        keywordsList: [], // 关键词组
        directionalList: [], // 定向信息
      },
      redio: 1, // 定向方式 （1关键字，2定向）
      checkAll: false,
      value: "",
      input: "",
      checkedCities: ["上海", "北京"],
      cities: ["1,", "22"],
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      isIndeterminate: true,
      dialogTableVisible: false,
      platform_type_list: JSON.parse(
        window.localStorage.getItem("platform_type")
      ),
      body_type_list: JSON.parse(window.localStorage.getItem("body_type")),
      parentId: null,
    };
  },
  mounted() {
   
  },
  methods: {
    // 打开弹出框
    dakai(type, list, id) {
      this.monitorData = list;
      this.dialogTableVisible = true;
      this.type = type;
      if (type == 2) {
        this.disableding = true;
      } else {
        if (id) {
          this.parentId = id;
          this.PlanData.planMonitor.parentId = id;
        }

        this.disableding = false;
      }
    },
    // 全选按钮
    CheckAllFn(idx, val) {
      // idx：类型  val：true为全选 false为非全选
      // 对象的key和idx匹配 就使用对应的值  值为数组 最大是两位 第二位留给backfn
      this.$main.selectAll(
        val,
        ...{
          1: [this.PlatformObj], // 平台类型
          2: [this.subjectObj], // 主体类型
        }[idx]
      );
    },
    // 多选按钮
    selectCheckAllFn(idx, { length }) {
      // idx：类型   length：已选数组的长度
      // 对象的key和idx匹配 就使用对应的值  值为数组 最大是两位 第二位留给backfn
      this.$main.selections(
        length,
        ...{
          1: [this.PlatformObj], // 平台类型
          2: [this.subjectObj], // 主体类型
        }[idx]
      );
    },
    // 添加 预警关键词
    warnKeywordFn(type) {
      const mubiaoList = {
        1: this.warnTypeIng.warnKeyword,
        2: this.warnTypeIng.warnAccount,
        3: this.warnTypeIng.warnWebsite,
      };
      let list = {
        1: this.warnSHuru.warnKeyword,
        2: this.warnSHuru.warnAccount,
        3: this.warnSHuru.warnWebsite,
      };
      let key = {
        1: this.warnSHuru.warnKey,
        2: this.warnSHuru.warnAccountKey,
        3: this.warnSHuru.warnWebsiteKey,
      };
      const message = {
        1: "关键词",
        2: "账号",
        3: "网站",
      };

      if (key[type].trim().length > 0) {
        list[type].push(key[type]);
        mubiaoList[type] = list[type];
        if (type == 1) {
          this.warnSHuru.warnKey = "";
        } else if (type == 2) {
          this.warnSHuru.warnAccountKey = "";
        } else if (type == 3) {
          this.warnSHuru.warnWebsiteKey = "";
        }
      } else {
        this.$message({
          message: `${message[type]}不能为空！`,
          type: "warning",
        });
      }
    },
    // 移除预警关键词
    taghandleClose(inx, type) {
      const mubiaoList = {
        1: this.warnTypeIng.warnKeyword,
        2: this.warnTypeIng.warnAccount,
        3: this.warnTypeIng.warnWebsite,
      };
      let list = {
        1: this.warnSHuru.warnKeyword,
        2: this.warnSHuru.warnAccount,
        3: this.warnSHuru.warnWebsite,
      };

      list[type].splice(inx, 1);
      mubiaoList[type] = list[type];
      if (type == 1) {
        this.warnTypeIng.warnKeyword = mubiaoList[type].join(",");
      } else if (type == 2) {
        this.warnTypeIng.warnAccount = mubiaoList[type].join(",");
      } else if (type == 3) {
        this.warnTypeIng.warnWebsite = mubiaoList[type].join(",");
      }
    },
    //  添加关键词组
    addKeyFn() {
      // 批量处理文字的trim()
      const { keywordName, subjectKeywordName } = this.$main.setTrims(
        this.AddKeyIng
      );
      if (keywordName.length > 0 && subjectKeywordName.length > 0) {
        this.PlanData.keywordsList.push(this.$main.setTrims(this.AddKeyIng));
        this.AddKeyIng = this.$options.data().AddKeyIng; // 重置
      } else {
        this.$message({
          message: "请填写完全后再添加",
          type: "warning",
        });
      }
    },
    // 添加定向词组
    addDirectionalFn() {
      const {
        platformType, //平台类型
        accountName, // 平台账号名称
        platformUrl, //账号网址
      } = this.$main.setTrims(this.directionalIng); // 批量处理非空校验
      if (
        platformType.length > 0 &&
        accountName.length > 0 &&
        platformUrl.length > 0
      ) {
        this.PlanData.directionalList.push(
          this.$main.setTrims(this.directionalIng)
        );
        this.directionalIng = this.$options.data().directionalIng; // 重置
      } else {
        this.$message({
          message: "请填写完全后再添加",
          type: "warning",
        });
      }
    },
    // 选择定向方式
    planTypeFn() {},
    // 回显的时候获取数据  不走props了
    async getMyinfo(data, id) {
      const {
        planMonitor: { bodyType, platformTypes, planType, parentId },
        warnList,
      } = data;
      this.$main.backMyInfo(this.subjectObj, bodyType); // 处理主体类型多选框
      this.$main.backMyInfo(this.PlatformObj, platformTypes); // 处理主体类型多选框
      this.redio = Number(planType); // 定向方式  （1关键字，2定向）

      Object.keys(data).forEach((key) => {
        // 有的数组后端传过来是null  所以得判断
        if (!data[key]) data[key] = [];
      });
      if (warnList && warnList.length > 0) {
        if (warnList[0].warnKeyword)
          this.warnSHuru.warnKeyword = warnList[0].warnKeyword.split(",");
        if (warnList[0].warnAccount)
          this.warnSHuru.warnAccount = warnList[0].warnAccount.split(",");
        if (warnList[0].warnWebsite)
          this.warnSHuru.warnWebsite = warnList[0].warnWebsite.split(",");
        this.warnTypeIng.warnType = warnList[0].warnType;
      }

      if (parentId == 0 || !parentId) data.planMonitor.parentId = null;
      // if (id) data.planMonitor.parentId = id;
      await this.$main.mySetTimeOut(100);
      data.directionalList.forEach((v) => {
        v.platformType = v.platformType + "";
      });
      this.PlanData = data; // 需要给后端的参数
    },

    // 确定
    okFn(status) {
      // warnTypeIng
      const { PlanData, PlatformObj, subjectObj, redio, type, warnSHuru } =
        this;
      // 检验必传值
      // if (!PlanData.planMonitor.startTime) {
      //   this.$message.warning("请选择开始时间！");
      //   return false;
      // }
      if (!PlanData.planMonitor.name) {
        this.$message.warning("请填写方案名称！");
        return false;
      }
      // 关键字
      if (redio == 1) {
        if (PlatformObj.checkedform.length < 1) {
          // 数据范围
          this.$message.warning("请勾选数据范围！");
          return false;
        }
        if (subjectObj.checkedform.length < 1) {
          // 主体类型
          this.$message.warning("请勾选主体类型！");
          return false;
        }
        if (PlanData.keywordsList.length < 1) {
          // 关键字组
          this.$message.warning("关键字组不能为空！");
          return false;
        }
        let ifWord = false;
        PlanData.keywordsList.forEach((v, i) => {
          if (ifWord) return;
          if (v.keywordName.trim().length == 0 || !v.keywordName) {
            this.$message.warning(`第${i + 1}列的词组名称不能为空`);
            ifWord = true;
            return;
          }
          if (
            v.subjectKeywordName.trim().length == 0 ||
            !v.subjectKeywordName
          ) {
            this.$message.warning(`第${i + 1}列的主关键词不能为空`);
            ifWord = true;
            return;
          }
        });
        if (ifWord) return;
      } else if (redio == 2) {
        // 定向
        if (PlanData.directionalList.length < 1) {
          //定向组
          this.$message.warning("请添加定向监测设置！");
          return false;
        }
        // 定向组监测
        let ifWord = false;
        PlanData.directionalList.forEach((v, i) => {
          if (ifWord) return;
          if (v.accountName.trim().length == 0 || !v.accountName) {
            this.$message.warning(`第${i + 1}列的定向名称不能为空`);
            ifWord = true;
            return;
          }
          if (v.platformUrl.trim().length == 0 || !v.platformUrl) {
            this.$message.warning(`第${i + 1}列的定向网址不能为空`);
            ifWord = true;
            return;
          }
        });
        if (ifWord) return;
      }
      PlanData.planMonitor.bodyType = subjectObj.checkedform
        .map((v) => v.value)
        .join(","); // 数据范围
      PlanData.planMonitor.platformTypes = PlatformObj.checkedform
        .map((v) => v.value)
        .join(","); // 主题类型
      if (type == 1) {
        // 新增
        // if (
        //   warnSHuru.warnKeyword.length > 0 ||
        //   warnSHuru.warnAccount.length > 0 ||
        //   warnSHuru.warnWebsite.length > 0
        // ) {
        PlanData.warnList = []; // 预警类型
        let obj = {};
        (obj.warnKeyword = warnSHuru.warnKeyword
          ? warnSHuru.warnKeyword.join(",")
          : ""),
          (obj.warnAccount = warnSHuru.warnAccount
            ? warnSHuru.warnAccount.join(",")
            : ""),
          (obj.warnWebsite = warnSHuru.warnWebsite
            ? warnSHuru.warnWebsite.join(",")
            : ""),
          (obj.warnType = this.warnTypeIng.warnType);
        PlanData.warnList.push(obj); // 预警类型
        PlanData.warnList.forEach((v, i) => {
          delete PlanData.warnList[i].warnKey;
          delete PlanData.warnList[i].warnAccountKey;
          delete PlanData.warnList[i].warnWebsiteKey;
        });
        // PlanData.keywordsList
        // if(PlanData.warnList.length == 0) {
        //   this.$message.warning("关键字词组不能为空！");
        // return false;
        // }
        // }
      } else if (type == 2) {
        // 编辑
        const warnTypeIng = {
          warnType: this.warnTypeIng.warnType,
          warnKeyword: this.warnSHuru.warnKeyword.join(","),
          warnAccount: this.warnSHuru.warnAccount.join(","),
          warnWebsite: this.warnSHuru.warnWebsite.join(","),
        };
        PlanData.warnList = []; // 预警类型
        PlanData.warnList.push(warnTypeIng); // 预警类型
      }

      if (!PlanData.planMonitor.parentId)
        PlanData.planMonitor.parentId = this.parentId ? this.parentId : 0;
      PlanData.planMonitor.planType = redio; //planType->类型（1关键字，2定向）
      PlanData.planMonitor.status = status; // 保存：1 保存并运行：0
      this.$emit("savePlanMonitor", { PlanData, type }); // type 1：保存 2：编辑
    },
    // 关闭弹出框 && 重置
    handleClose() {
      this.dialogTableVisible = false;
      const {
        AddKeyIng, // 关键词组输入栏重置
        warnTypeIng, // 预警类型输入栏重置
        PlanData, //需要给后端的参数 重置
        PlatformObj, // 数据范围 重置
        subjectObj, // 主体类型 重置
        warnSHuru,
        redio,
        directionalIng,
      } = this;
      // 批量处理重置
      this.$main.setReset(
        {
          AddKeyIng, // 关键词组输入栏重置
          warnTypeIng, // 预警类型输入栏重置
          PlanData, //需要给后端的参数 重置
          PlatformObj, // 数据范围 重置
          subjectObj, // 主体类型 重置
          warnSHuru,
          redio,
          directionalIng,
        },
        this
      );
    },
  },
};
</script>

<style scoped lang="scss">
.input-flex-item {
  display: flex;
  justify-content: end;
}
.flex-center {
  height: 100%;
  display: flex;
  align-items: center;
}
.redtext {
  color: red;
  margin: 0 3px 0 3px;
}
</style>
<style >
.guanjianci {
  display: flex;
  align-items: center;
}
.guanjianci-L {
  width: 80px;
}
.warningFlex {
  /* justify-content: space-between; */
}
.warningInput {
  width: 83%;
}
.warningBtn {
  height: 36px;
}
</style>